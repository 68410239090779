:root {
  --app-height: 100vh;
  --topbar-height: 0;
  --toolbar-width: 0;
}

body {
  height: var(--app-height);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #dadce0 transparent;
}

:hover {
  scrollbar-color: #bdc1c6 transparent;
}

::-webkit-scrollbar {
  background: transparent;
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border: none;
  box-shadow: none;
  background: #dadce0;
  border-radius: 3px;
  min-height: 40px;
}

::-webkit-scrollbar-track {
  margin: 1px 0;
}

:hover::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #bdc1c6;
}

.hover-child-eager {
  transition: opacity 0.2s ease-in;
}

.hover-parent-movement-on .hover-child-eager {
  opacity: 1;
}

.hover-parent-movement-off .hover-child-eager {
  opacity: 0;
}

.word-break {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.button-appearance-none {
  appearance: none;
  background-color: transparent;
  border: none;
}

.max-w-prose {
  max-width: 65ch;
}
